.bloop {
  box-shadow: 0px 0px 10px green inset !important;
}

/* @keyframes example {
    from {
        padding: "5px";
    }
    to {
        padding: "15px";
    }
} */

/* .bloomer {
    padding: "5px" !important;
    /* animation: example 1s infinite !important; */

/* animation-name: example; */

/* animation-duration: 1s; */

/* transition: padding 1s !important;
-webkit-transition: padding 1s !important;
} */

.text {
  display: none !important;
}

.bloomer:hover > .text {
  display: block !important;
}

/* The animation code */

@keyframes example {
  0% {
    background-color: transparent;
    /* transform: scale(1) !important;
        -webkit-transform: scale(1) !important; */
    padding: 5px;
    margin-left: 5px;
  }
  /* 25% {
        background-color: yellow;
    }
    50% {
        background-color: blue;
    } */
  100% {
    background-color: red;
    /* transform: scale(2) !important;
        -webkit-transform: scale(2) !important; */
    padding: 10px;
    margin-left: 0px;
  }
}

@keyframes exampleResolve {
  0% {
    background-color: transparent;
    padding: 5px;
    margin-left: 5px;
  }

  100% {
    background-color: green;
    padding: 10px;
    margin-left: 0px;
  }
}

@keyframes exampleFallBackTest {
  0% {
    background-color: transparent;
    padding: 5px;
    margin-left: 5px;
  }
  100% {
    background-color: orange;
    padding: 10px;
    margin-left: 0px;
  }
}

/* The element to apply the animation to */

.bloomer {
  /* width: 100px;
    height: 100px; */
  background-color: red;
  padding: 1px;
  /* transform: scale(1) !important;
    -webkit-transform: scale(1) !important; */
  /* transition: transform 1s; */
  animation-name: example;
  animation-direction: alternate !important;
  animation-iteration-count: infinite !important;
  animation-duration: 0.5s;
}

.inverse-bloomer {
  /* width: 100px;
    height: 100px; */
  background-color: transparent !important;
  padding: 10px !important;
  /* animation-name: example;
    animation-direction: alternate !important;
    animation-iteration-count: infinite !important;
    animation-duration: 0.5s; */
}

.bloomer-resolve {
  /* width: 100px;
    height: 100px; */
  background-color: green;
  padding: 1px;
  /* transform: scale(1) !important;
    -webkit-transform: scale(1) !important; */
  /* transition: transform 1s; */
  animation-name: exampleResolve;
  animation-direction: alternate !important;
  animation-iteration-count: infinite !important;
  animation-duration: 0.5s;
}

.bloomer-fallback {
  /* width: 100px;
    height: 100px; */
  background-color: orange;
  padding: 1px;
  /* transform: scale(1) !important;
    -webkit-transform: scale(1) !important; */
  /* transition: transform 1s; */
  animation-name: exampleFallBackTest;
  animation-direction: alternate !important;
  animation-iteration-count: infinite !important;
  animation-duration: 0.5s;
}

.inverse-bloomer-resolve {
  /* width: 100px;
    height: 100px; */
  background-color: transparent !important;
  padding: 10px !important;
  /* animation-name: example;
    animation-direction: alternate !important;
    animation-iteration-count: infinite !important;
    animation-duration: 0.5s; */
}
